code {
  font-family: "Playfair Display";
}

body {
  background-image: linear-gradient(
    118.4deg,
    rgba(96, 32, 48, 1) 27.5%,
    rgba(81, 0, 0, 1) 92.7%
  );
  /* background-color: #ad2f26;
  background-image: linear-gradient(147deg, #ad2f26 0%, #99201c 74%); */

  color: #602030;
  margin: 0;

  font-family: "Playfair Display";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
a,
li,
p {
  font-family: "Playfair Display";
}

.logo {
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}

div.gradientCircle {
  height: 20vw;
  width: 20vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  color: #fff;
}
div.gradientCircle:hover {
  background: rgb(96, 32, 48);
  color: #fff;
}

div.gradientCircleMobile {
  height: 80vw;
  width: 80vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px;
  color: #fff;
}
div.gradientCircleMobile:hover {
  background: rgb(96, 32, 48);
  color: #fff;
}

form {
  max-width: 300px !important;
}
