$theme-colors: (
  "primary": rgb(96, 32, 48),
  "secondary": #fff,
);

.bm-overlay {
  //
}

.bm-burger-button {
  position: absolute;
  top: 8px;
  left: 8px;
}

.bm-menu {
  background: #602030 !important;
}

.bm-item {
  border: none;
  border-bottom-color: #602030;
}

.bm-item:focus {
  outline: none;
}

#react-burger-menu-btn {
  height: 100px !important;
}

a.circle {
  color: #fff;
}

a.circle:hover {
  text-decoration: none;
  color: #fff;
}

$enable-shadows: true;

@import "~bootstrap/scss/bootstrap";
